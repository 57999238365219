import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Original Pen by Matt Smith
//const second = 1000,
//  minute = second * 60,
//  hour = minute * 60,
// day = hour * 24;

// let countDown = new Date('Oct 19, 2022 23:00:00 GMT+7').getTime(),
// x = setInterval(function () {
//   let now = new Date().getTime(),
//     distance = countDown - now;

//   (document.getElementById('days').innerText = Math.floor(distance / day)),
//    (document.getElementById('hours').innerText = Math.floor(
////     (distance % day) / hour,
//   )),
//    (document.getElementById('minutes').innerText = Math.floor(
//     (distance % hour) / minute,
//   )),
// (document.getElementById('seconds').innerText = Math.floor(
//    (distance % minute) / second,
//  ));
// }, second);

window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag('js', new Date());

gtag('config', 'G-LG5434C013');
