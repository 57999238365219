import {
    Bar,
    CurrencyItem,
    DatafeedConfiguration,
    GetMarksCallback,
    HistoryCallback,
    IChartingLibraryWidget,
    IDatafeedChartApi,
    IDatafeedQuotesApi,
    IExternalDatafeed,
    LibrarySymbolInfo,
    Mark,
    OnReadyCallback,
    PeriodParams,
    QuotesCallback,
    ResolutionString,
    ResolveCallback,
    SearchSymbolResultItem,
    SearchSymbolsCallback,
    ServerTimeCallback,
    SubscribeBarsCallback,
    SymbolResolveExtension,
    TimescaleMark
} from '../../charting_library';

import { Market } from '@project-serum/serum';
import { Connection } from '@solana/web3.js';
import { useMarketsList, useTokensList } from '../../utils/markets';
import { listMarket } from '../../utils/send';

const usedMarkets = useMarketsList();
const usedTokens = useTokensList();

const supportedMarkets: SearchSymbolResultItem[] = usedMarkets.filter((_) => !_.deprecated).map((_) => {
    return {
        symbol: _.name,
        full_name: _.name,
        description: "",
        exchange: "Companion DEX",
        ticker: _.name,
        type: "Crypto"
    };
});

function getMarket(name: string) {
    var res = usedMarkets.filter((_) => !_.deprecated).find((_) => _.name === name);
    return res;
}

function resolutionToTimeSpan(resolution: string) {
    var minSpan = 60 * 1000;
    var hourSpan = 60 * minSpan;
    var daySpan = 24 * hourSpan;
    switch (resolution) {
        case '1': return minSpan;
        case '5': return minSpan * 5;
        case '15': return minSpan * 15;
        case '30': return minSpan * 30;
        case '60': return hourSpan;
        case '1D': return daySpan;
        case '1W': return daySpan * 7;
    }
}

export class CMPNDF implements IDatafeedChartApi, IExternalDatafeed, IDatafeedQuotesApi {
    _tickers: { [id: string]: any; } = {};
    _chart: IChartingLibraryWidget;
    _connection: Connection;
    _configs: DatafeedConfiguration = {
        supported_resolutions: [
            '1',
            '5',
            '15',
            '30',
            '60',
            '1D',
            '1W',
        ] as any,
        // currency_codes: supportedMarkets,
        supports_marks: false,
        supports_timescale_marks: false,
    };

    constructor(connection: Connection) {
        this._connection = connection;
    }
    onReady(callback: OnReadyCallback) {
        callback(this._configs);
    }
    getQuotes(symbols: string[], onDataCallback: QuotesCallback, onErrorCallback: (msg: string) => void) {
        // this._quotesProvider.getQuotes(symbols).then(onDataCallback).catch(onErrorCallback);
        onErrorCallback("Not implemented");
    }
    subscribeQuotes(symbols: string[], fastSymbols: string[], onRealtimeCallback: QuotesCallback, listenerGUID: string): void {
        // this._quotesPulseProvider.subscribeQuotes(symbols, fastSymbols, onRealtimeCallback, listenerGuid);
    }
    unsubscribeQuotes(listenerGUID: string): void {
        // this._quotesPulseProvider.unsubscribeQuotes(listenerGuid);
    }
    searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback) {
        var response = supportedMarkets.filter((_) => _.symbol.toUpperCase().indexOf(userInput.toUpperCase()) != -1);
        onResult(response);
    }
    async resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError, extension?: SymbolResolveExtension) {
        var token = supportedMarkets.find((_) => _.symbol.toUpperCase() === symbolName.toUpperCase());
        if (!token) {
            onError("Token not exists");
        }

        var market = getMarket(token.symbol);
        if (!market) {
            onError("Token not exists");
        }

        const marketInfo = await Market.load(this._connection, market.address, {},
            market.programId);

        onResolve({
            description: token.description,
            exchange: token.exchange,
            full_name: token.full_name,
            format: "price",
            listed_exchange: "",
            minmov: 1,
            name: token.full_name,
            pricescale: Math.round(1 / marketInfo.tickSize),
            fractional: false,
            session: "24x7",
            supported_resolutions: this._configs.supported_resolutions,
            timezone: "Etc/UTC",
            type: "Crypto",
            has_intraday: true
        });
    }
    async getBars(symbsymbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParams, onResult: HistoryCallback, onError) {
        // if (symbsymbolInfo.name === "CMPN/USDC") {
        //     onError("Market history will be available soon");
        //     return;
        // }
        try {
            var res = await this._getBars(symbsymbolInfo, resolution, periodParams.from, periodParams.to);
            onResult(res, {
                noData: res.length === 0
                // nextTime?: number | null;
            });
        } catch (ex) {
            onError();
        }

    }
    async subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void) {
        const connection = new Connection('https://solana-api.projectserum.com');
        const pk = getMarket(symbolInfo.name);
        this._tickers[listenerGuid] = {
            pk,
            symbolInfo,
            resolution,
            onTick,
            connection
        };

        // this._tickers[listenerGuid] = setInterval(() => {
        //     try {
        //         var lastBar = (this._chart.chart().getSeries() as any).data().bars().last().value;
        //         var date = lastBar[0];
        //         var open = lastBar[1];
        //         var high = lastBar[2];
        //         var low = lastBar[3];
        //         var close = lastBar[4];
        //         var volume = lastBar[5];
        //         var timeCurrent = lastBar[6];
        //         var random = Math.random() * 10;
        //         var newClose = close + random;
        //         // onTick({
        //         //     time: new Date().getTime(),
        //         //     open: open,
        //         //     high: Math.max(high, newClose),
        //         //     low: Math.min(low, newClose),
        //         //     close: newClose,
        //         //     volume: volume + 1
        //         // });
        //     } catch (ex) { }
        // }, 1000);
    }

    unsubscribeBars(listenerGuid: string) {
        delete this._tickers[listenerGuid];
    }

    setChart(tvWidget: IChartingLibraryWidget) {
        this._chart = tvWidget;
    }

    setPrice(price: number, market: Market) {
        try {
            if (!this._chart || !this._chart.chart().dataReady(() => { })) {
                return;
            }

            for (var id in this._tickers) {
                var ticker = this._tickers[id];
                if (!ticker) {
                    continue;
                }
                if (ticker.pk.address.toString() !== market.address.toString()) {
                    continue;
                }

                try {
                    var timeSpan = resolutionToTimeSpan(ticker.resolution);
                    if (!timeSpan) {
                        return;
                    }
                    var dateNow = new Date().getTime();
                    var lastBar = (this._chart.chart().getSeries() as any).data().bars().last().value;
                    var date = lastBar[0] * 1000;
                    var open = lastBar[1];
                    var high = lastBar[2];
                    var low = lastBar[3];
                    var close = lastBar[4];
                    var volume = lastBar[5];
                    var timeCurrent = lastBar[6];
                    var newClose = price;

                    if (dateNow >= date + timeSpan) {
                        open = newClose;
                        high = newClose;
                        low = newClose;
                    }
                    ticker.onTick({
                        time: dateNow,
                        open: open,
                        high: Math.max(high, newClose),
                        low: Math.min(low, newClose),
                        close: newClose
                    });
                } catch (ex) { }
            }
        } catch (ex) {
            return;
        }
    }

    _getBars(symbsymbolInfo: LibrarySymbolInfo, resolution: ResolutionString, from: number, to: number): Promise<Bar[]> {
        return new Promise((resolve, reject) => {
            try {
                var address = getMarket(symbsymbolInfo.name);
                if (!address) {
                    reject();
                    return;
                }

                var tokens = symbsymbolInfo.ticker.split("/");
                var token1 = tokens[0];
                var token2 = tokens[1];

                if (!token2 || !token1) {
                    reject();
                    return;
                }

                token2 = token2.toLowerCase();
                if (token2 === 'usdc' || token2 === 'usdt') {
                    token2 = 'usd';
                } else {
                    reject();
                    return;
                }

                var toke1Address = usedTokens.find((_) => _.name.toLowerCase() === token1.toLowerCase());
                if (!toke1Address) {
                    reject();
                    return;
                }

                const requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                };
                fetch(`https://prism-trackerapi-stage.companion.to/history?address=${toke1Address.address.toString()}&currency=${token2}&type=${toTimeframe(resolution)}&from=${from}&to=${to}`, requestOptions)
                    .then(response => response.json())
                    .then((data) => {
                        if (!data.success) {
                            reject();
                            return;
                        }

                        try {
                            var ohlcv = data.data.items;
                            var bars: Bar[] = [];
                            for (var i = 0; i < ohlcv.length; i++) {
                                // 1666195200
                                var time = ohlcv[i].unixTime * 1000;
                                var weekShift = resolutionToTimeSpan("1W");
                                var lunchTime = 1666195200000;
                                var open = ohlcv[i].o;
                                var high = ohlcv[i].h;
                                var low = ohlcv[i].l;
                                var close = ohlcv[i].c;
                                var volume = ohlcv[i].v;
                                if (address.name === "CMPN/USDC" && time > lunchTime - weekShift && time < lunchTime + weekShift) {
                                    if (open > 0.9) {
                                        open = 0.04;
                                    }
                                    if (high > 0.9) {
                                        high = 0.04;
                                    }
                                    if (low > 0.9) {
                                        low = 0.04;
                                    }
                                    if (close > 0.9) {
                                        close = 0.04;
                                    }
                                }

                                var bonkFilter = 1671840000000;
                                if (address.name === "BONK/USDC" && time > bonkFilter - weekShift && time < bonkFilter + weekShift) {
                                    if (high > 0.0001) {
                                        continue;
                                    }
                                }
                                bars.push({
                                    open: open,
                                    high: high,
                                    low: low,
                                    close: close,
                                    volume: volume,
                                    time: time,
                                });
                            }
                            resolve(bars);
                            return;
                        } catch (ex) {
                            reject();
                            return;
                        }
                    });
            } catch (ex) {
                reject();
                return;
            }
        });
    }
}

function toTimeframe(resolution: ResolutionString) {
    switch (resolution) {
        case '1': return "1m";
        case '5': return "5m";
        case '15': return "15m";
        case '30': return "30m";
        case '60': return "1H";
        case '1D': return "1D";
        case '1W': return "1W";
        case '1M': return "1M";
    }
    return resolution;
}

function defaultConfiguration() {
    return {
        supports_search: false,
        supports_group_request: true,
        supported_resolutions: [
            '1',
            '5',
            '15',
            '30',
            '60',
            '1D',
            '1W',
            '1M',
        ],
        supports_marks: false,
        supports_timescale_marks: false,
    };
}