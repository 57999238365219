import React from 'react';
import { Layout, Row, Col, Grid } from 'antd';
import Link from './Link';
import { helpUrls } from './HelpUrls';
import { useReferrer } from '../utils/referrer';
const { Footer } = Layout;
const { useBreakpoint } = Grid;

const footerElements = [
  {
    description: 'Serum Developer Resources',
    link: helpUrls.developerResources,
  },
  { description: 'Discord', link: helpUrls.discord },
  { description: 'Telegram', link: helpUrls.telegram },
  { description: 'GitHub', link: helpUrls.github },
  { description: 'Project Serum', link: helpUrls.projectSerum },
  { description: 'Solana Network', link: helpUrls.solanaBeach },
];

export const CustomFooter = () => {
  const smallScreen = !useBreakpoint().lg;
  const { refCode, allowRefLink } = useReferrer();
  return (
    <Footer
      style={{
        height: '35px',
        padding: '0px 12px',
        zIndex: 10,
        display: 'flex',
        width: '100%',
        bottom: 0,
        position: 'fixed'
      }}
    >
      {/* {refCode && allowRefLink && (
        <Row justify="center">Your referrer is {refCode}</Row>
      )}    */}
        <Row  style={{alignItems: 'center',width: '100%',justifyContent: 'space-between'}}>
        <>
          <div id='leftside'>

            <a id='operationalholder' href="https://companionto.statuspage.io/">
              <div id='operational'><span id='operationallight'></span> <span id='operationalstatus'>Operational</span>
              </div>
            </a>
          </div>

          <div id='rightside'>
            <a id='legacy' href="https://legacy-exchange.companion.to/#/market/GHgWKwDptCj5k5DMsLbca6BB5SWVK61Ygs9a1ciEg9jF">Legacy Exchange</a>
            <a id='legacy' href="https://exchange.companion.to/#/market/6YJNt7nvB6WpFDPD7nBcun31QnHcmYRxomHD63TjehSq">Exchange</a>
          </div>


          {/*
            {footerElements.map((elem, index) => {
              return (
                <Col key={index + ''}>
                  <Link external to={elem.link}>
                    {elem.description}
                  </Link>
                </Col>
              );
            })}
          */ }


        </>




      </Row>
    </Footer>
  );
};
