import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
html,body{
  background: #191d27;
  font-size: 12px;
 
}
input[type=number]::-webkit-inner-spin-button {
  opacity: 0;
}
input[type=number]:hover::-webkit-inner-spin-button,
input[type=number]:focus::-webkit-inner-spin-button {
  opacity: 0.25;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #2d313c;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #5b5f67;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5b5f67;
}
.ant-slider-track, .ant-slider:hover .ant-slider-track {
  background-color: #fff;
  opacity: 0.75;
}
.ant-slider-track,
.ant-slider ant-slider-track:hover {
  background-color: #fff;
  opacity: 0.75;
}
.ant-slider-dot-active,
.ant-slider-handle,
.ant-slider-handle-click-focused,
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open)  {
  border: 2px solid #fff; 
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #273043;
}
.ant-table-tbody > tr > td {
  border-bottom: 8px solid #1A2029;
}
.ant-table-container table > thead > tr:first-child th {
  border-bottom: none;
}
.ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid #434a59 !important;
}
.ant-layout {
    background: #191d27;
  }
  .ant-table {
    background: #212734;
  }
  .ant-table-thead > tr > th {
    background: #191d27;
  }
.ant-select-item-option-content {
  img {
    margin-right: 4px;
  }

 
}

.ant-row.ant-row-middle{
  padding:8px 16px 0px 16px;

  
}


@media (max-width: 740px) {
  .ant-row.ant-row-middle{
    padding:8px !important;
  }
}
@media (max-width: 420px) {
  .ant-row.ant-row-middle{
    padding:4px !important;
  }
  #topwrapper{
    padding:0px 8px !important;
  }
}





.ant-select-selector {
  border-radius: 0px !important;
  overflow:hidden;
  background-color:#1c202a !important;


 }

 .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0px transparent !important;



 }

 .ant-select-single:not(.ant-select-customize-input) .ant-select-selector
 {
  height: 30px;



 }

 .ant-select:not(.ant-select-disabled):hover .ant-select-selector {

  border-right-width: 1px !important;
  border: 1px solid #f0b90b;


}

.ant-modal-content {
  background-color: #212734;
}

@-webkit-keyframes highlight {
  from { background-color: #fff;}
  to {background-color: #1A2029;}
}
@-moz-keyframes highlight {
  from { background-color: #fff;}
  to {background-color: #1A2029;}
}
@-keyframes highlight {
  from { background-color: #fff;}
  to {background-color: #1A2029;}
}
.flash {
  -moz-animation: highlight 0.5s ease 0s 1 alternate ;
  -webkit-animation: highlight 0.5s ease 0s 1 alternate;
  animation: highlight 0.5s ease 0s 1 alternate;
}

img#chart-wrapper {
  width: 100%;
  min-width:300px;
  height:100%;
  display:flex;
  padding:8px;
  margin: 5px;
  border: 1px solid #dddddd2b;

  
}

#chart-dev  {

  display:flex;
  width:100%;

  
}

#leftcol, #leftcolx {
  padding-right: 4px;
}

#rightcol, #rightcolx {

  padding-left: 4px;
}


#rower:nth-child(odd) {
  background-color: #eaecef0f;
}

#chartwrapper{
  
  width:100%;
  
  
}

.ant-tabs-nav{
  color: #EDEDED;
  padding: 0px 8px;
  background:#1c202a;
  width:100%;
  font-weight:600;
  font-size:12px !important;
}
.ant-tabs-tab-btn{
  font-size:12px;
}


#windowheader{
  color: #EDEDED;
  padding: 4px 8px;
  background:#393e461a;
  width:100%;
  font-weight:600;
}

.ant-slider.ant-slider-with-marks {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top:15px;
}

#optionswrapper{
  padding:8px 16px;
  background:#1c202a;
  border-radius:5px;
  font-weight:700;
  font-size:11px;
  margin-top:16px;
}

`;
