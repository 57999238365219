import { PublicKey } from "@solana/web3.js";
import markets_json from '../assets/markets.json';

export const CUSTOM_MARKETS = markets_json.map((market) => {
    return {
        address: new PublicKey(market.address),
        name: market.name,
        programId: new PublicKey(market.programId),
        deprecated: market.deprecated,
    };
});